<template>
  <div class="installationInputWrapper">
    <div class="mainHeadlineWrapper">
      <h2 class="headline">Einbau</h2>
      <div class="infoIconWrapper" style="margin-top:1.2rem">
        <ToolTip tipText="<strong>Einbau Staubdicht:</strong>
          Rahmeninnenleben wird vor dem Einbau mit säurefreiem Klebeband
          staubdicht miteinader verklebt. Zudem wird der Rahmen von hinten mit
          säurefreiem Klebeband verschlossen.
          <br>
          <br>
          <strong>Einbau Standard:</strong> Die Komponenten des
          Rahmeninnenlebens werden nicht miteinander verklebt. Der Rahmen wird
          lediglich von hinten mit säurefreiem Klebeband verschlossen.
          <br>
          <br>

          <strong>Einbau Keilrahmen:</strong> Ihr Keilrahmenbild bzw. das zum
          Aufspannen und Einrahmen mitgegebene Keilrahmenbild wird mit
          Blendrahmenblechen im gewählten Rahmen angebracht.
          <br>
          <br>"></ToolTip>
      </div>
    </div>
    <label class="checkboxContainer"
      v-for="(type, index) in filteredInstallationTypes" :key="type.key"
      style="margin-bottom: 1rem; margin-top: 2rem">
      {{ type.value }}
      <input :id="`checkbox-${index}`" type="radio"
        v-model="selectedInstallation" :value="type"
        @change="setWorkshopInstallation" />
      <span class="checkmark"></span>
    </label>
  </div>
</template>

<script>
import InstallationTypes from "@/models/Types/InstallationTypes";
import InstallationTypes_BAUHAUS from "@/models/Types/BAUHAUS/InstallationTypes_BAUHAUS";
import InstallationTypes_XXXL from "@/models/Types/XXXL_GROUP/InstallationTypes_XXXL";
import UserTypes from "@/models/Types/UserTypes";
import WorkshopModes from "@/models/Types/WorkshopModes";
import UserTypeProvider from "@/utility/UserTypeProvider";
import ToolTip from "@/components/shared/ToolTip.vue";
export default {
  components: {
    ToolTip,
  },
  props: ["syncConfiguration"],

  data() {
    return {
      installationTypes: UserTypeProvider.getUserType() === UserTypes.RETAILER ? InstallationTypes : UserTypeProvider.getUserType() === UserTypes.BAUHAUS ? InstallationTypes_BAUHAUS : InstallationTypes_XXXL,

      selectedInstallation: null,
    };
  },
  created() {
    this.matchInstallationsToWorkshop();
  },
  watch: {
    workshopWorkInstallations: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.matchInstallationsToWorkshop();
        }
      },
      deep: true,
    },
  },
  methods: {
    matchInstallationsToWorkshop() {
      // This assumes workshopWorkInstallations is a type object or null
      const installationInWorkshop = this.workshopWorkInstallations;
      // Check if the current installation is in workshopWorkInstallations
      if (installationInWorkshop) {
        this.selectedInstallation = this.installationTypes.find(
          (type) => type.key === installationInWorkshop.key
        );
      } else {
        // Default to the first installation type if none is set in the workshop
        this.selectedInstallation =
          this.installationTypes.length > 0 ? this.filteredInstallationTypes[0] : null;
        this.setWorkshopInstallation()
      }
    },
    setWorkshopInstallation() {
      this.workshopWorkInstallations = this.selectedInstallation;
      // Additional logic based on specific key
      if (
        this.selectedInstallation &&
        this.selectedInstallation.key === "EKR33081"
      ) {
        this.$store.commit("switchWorkshopGlas", "");
        this.$store.commit("switchWorkshopBackWall", "");
        this.$store.commit("setWorkshopPassepartout", "");
      }
      console.log(this.currentWorkshopWork);

      this.syncConfiguration();
    },
  },
  computed: {
    workshopWorkInstallations: {
      get() {
        return this.$store.getters.currentWorkshopWork.installation;
      },
      set(value) {
        this.$store.commit("setWorkshopInstallation", value);
      },
    },
    currentWorkshopWork() {
      return this.$store.getters.currentWorkshopWork;
    },
    currentWorkshopMode() {
      return this.$store.getters.currentWorkshopMode;
    },
    filteredInstallationTypes() {
      if (this.currentWorkshopMode === WorkshopModes.CANVAS_FRAMING) {
        return this.installationTypes.filter(
          (type) => type.key === "EKR33081" && type.key !== "DOPPEL_GLAS"
        );
      } else if (this.currentWorkshopMode === WorkshopModes.LAMINATION_FRAMING) {
        return this.installationTypes.filter(
          (type) => type.key !== "EKR33081" && type.key !== "DOPPEL_GLAS"
        );
      } else {
        return this.installationTypes.filter((type) => {
          if (!this.currentWorkshopWork.double_glas) {
            return type.key !== "DOPPEL_GLAS";
          } else {
            return type.key === "DOPPEL_GLAS";
          }
        });
      }
    },


  },
};
</script>

<style lang="scss">
@import "../../../../../assets/sass/style.scss";

.installationInputWrapper {
  .mainHeadlineWrapper {
    display: flex;
    align-items: center;

    gap: .5rem;

    @media (max-width: $mobile) {
      width: 95vw;
    }

    @media (min-width: $mobile) and (max-width: $tablet) {
      width: 80vw;
    }

    max-width: 850px;

    .headline {
      margin-bottom: 0;

      text-align: left;
    }
  }
}

.checkboxContainer {
  z-index: 1;
  display: flex;
  position: relative;
  padding-left: 30px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: 0;

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 50px;
    background-color: $ibis-grey-middle;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  input:checked~.checkmark {
    background-color: $ibis-red;
    transition: 0.15s;

    &:after {
      display: block;
    }
  }

  .checkmark:after {
    left: 6.5px;
    top: 4px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
</style>
