<template>
  <div class="mainOptionsWrapper">
    <div class="optionsWrapper">
      <div class="topRow">
        <div>
          <NonConfigurableFreeSpaceDummy :roundToTwo="roundToTwo">
          </NonConfigurableFreeSpaceDummy>
        </div>
        <div v-if="currentProduct.productType === productTypes.GLAS_BOARD &&
            currentProductFamily.glas_size_options
            ">
          <TopGlasGroupOptions :validateQuantityInput="validateQuantityInput"
            :extractWidth="extractWidth" :extractHeight="extractHeight">
          </TopGlasGroupOptions>
        </div>
        <div v-if="currentProduct.productType === productTypes.CUT ||
            currentProduct.productType === productTypes.FRAME ||
            currentProduct.sub_category === productOptionsToSort.CORNER_FRAME
            ">
          <TopCutAndFrameOptions :updatePrice="updatePrice"
            :widthValidation="widthValidation"
            :heightValidation="heightValidation"></TopCutAndFrameOptions>
        </div>
      </div>

      <div class="bottomRow">
        <div v-if="currentProduct.productType === productTypes.CUT">
          <BottomCutOptions
            :onDenominationTypeChange="onDenominationTypeChange">
          </BottomCutOptions>
        </div>

        <div v-if="currentProduct.familyType === familyTypes.PASSEPARTOUT &&
            currentProduct.productType === productTypes.CUT
            ">
          <PassepartoutOptions :updatePrice="updatePrice"
            :innerWidthValidation="innerWidthValidation"
            :innerHeightValidation="innerHeightValidation"
            :selectAll="selectAll" :syncConfiguration="syncConfiguration"
            :onNoCutOutInput="onNoCutOutInput"></PassepartoutOptions>
        </div>
        <div>
          <CheckboxOptions :syncConfiguration="syncConfiguration"
            :onWithBridgeInput="onWithBridgeInput"
            :onWithCrossInput="onWithCrossInput"></CheckboxOptions>
        </div>
        <div v-if="(currentProduct.max_width && currentProduct.max_height) ||
            (currentProduct.min_width && currentProduct.min_height)
            ">
          <ProductRestrictions></ProductRestrictions>
        </div>
        <div>
          <AmountInput :doubleInputValidation="doubleInputValidation">
          </AmountInput>
        </div>
        <div>
          <ProductNotes />
        </div>
        <div>
          <PositionPrice :roundToTwo="roundToTwo"></PositionPrice>
        </div>
        <div class="addToOrderButtonWrapper">
          <IbisButton buttonClass="primary"
            buttonText="Zur Bestellung hinzufügen +"
            :buttonDisabled="isInputIncomplete || isOrderProcessing"
            @click="addProductToOrder()"></IbisButton>
        </div>
      </div>
    </div>
    <div class="sizeUnitWrapper">
      <div v-if="currentProduct.productType !== productTypes.POLE &&
            currentProduct.productType !== productTypes.NON_CONFIGURABLE &&
            currentProduct.familyType !== familyTypes.GLAS
            ">
        cm
      </div>
      <div v-if="currentProduct.familyType === familyTypes.PASSEPARTOUT &&
            currentProduct.productType === productTypes.CUT &&
            currentProduct.familyType !== familyTypes.GLAS
            " v-show="!currentProduct.passepartout_no_cut_out"
        :style="currentProduct.familyType === familyTypes.PASSEPARTOUT ? 'margin-top: 13.9em' : 'margin-top: 7.6em'">
        cm
      </div>
    </div>
  </div>
</template>
<script>
import ProductTypes from "@/models/Types/ProductTypes";
import Denominations from "@/models/Types/Denominations";
import FamilyTypes from "@/models/Types/FamilyTypes";
import ProductNotes from "../shared/ProductNotes.vue";
import ToastTypes from "@/models/Types/ToastTypes";
import ProductOptionsToSort from "@/models/Types/ProductOptionsToSort";
import _baseIsEqual from "lodash/_baseIsEqual";
import IbisButton from "@/components/shared/IbisButton.vue";

import NonConfigurableFreeSpaceDummy from "@/components/configurator/shared/Information/NonConfigurableFreeSpaceDummy";
import TopGlasGroupOptions from "@/components/configurator/shared/Options/TopGlasGroupOptions";
import TopCutAndFrameOptions from "@/components/configurator/shared/Options/TopCutAndFrameOptions";
import BottomCutOptions from "@/components/configurator/shared/Options/BottomCutOptions";
import PassepartoutOptions from "@/components/configurator/shared/Options/PassepartoutOptions";
import CheckboxOptions from "@/components/configurator/shared/Options/CheckboxOptions";
import ProductRestrictions from "@/components/configurator/shared/Information/ProductRestrictions";
import PositionPrice from "@/components/configurator/shared/Information/PositionPrice";
import AmountInput from "@/components/configurator/shared/Options/AmountInput";
import DBSyncer from "@/utility/DBSyncer";

export default {
  components: {
    ProductNotes,
    IbisButton,

    NonConfigurableFreeSpaceDummy,
    TopGlasGroupOptions,
    TopCutAndFrameOptions,
    BottomCutOptions,
    PassepartoutOptions,
    CheckboxOptions,
    ProductRestrictions,
    AmountInput,
    PositionPrice,
  },

  computed: {
    currentProduct() {
      return this.$store.getters.currentProduct;
    },
    currentProductFamily() {
      return this.$store.getters.selectedProductFamily;
    },
    selectedConfigurations() {
      return this.$store.getters.selectedConfigurations;
    },
    currentGlasOption: {
      get() {
        return this.$store.getters.currentGlasSizeOption;
      },
      set(newValue) {
        this.$store.commit("switchGlasSizeOption", newValue);
      },
    },
    selectedIndex() {
      return this.$store.getters.selectedIndex;
    },
    totalPrice() {
      return this.$store.getters.totalPrice;
    },
    isInputIncomplete() {
      for (const product of this.selectedConfigurations) {
        if (_baseIsEqual(product, this.currentProduct)) {
          return true;
        }
      }

      if (
        this.currentProduct.productType === this.productTypes.CUT &&
        this.currentProduct.familyType !== this.familyTypes.PASSEPARTOUT
      ) {
        if (
          this.currentProduct.selected_denomination_type ===
          this.denominations.FRAMES
        ) {
          if (!(this.currentProduct.width && this.currentProduct.height)) {
            return true;
          }
        } else {
          
          if (!this.currentProduct.width && !this.currentProduct.height) {
            return true;
          }
        }
      } else if (
        this.currentProduct.productType === this.productTypes.CUT &&
        this.currentProduct.familyType === this.familyTypes.PASSEPARTOUT
      ) {
        if (
          this.currentProduct.passepartout_no_cut_out &&
          !this.currentProduct.width
        ) {
          return true;
        } else if (
          !this.currentProduct.passepartout_no_cut_out &&
          (!this.currentProduct.width ||
            !this.currentProduct.height ||
            !this.currentProduct.passepartout_inner_width ||
            !this.currentProduct.passepartout_inner_height)
        ) {
          return true;
        }
      } else if (this.currentProduct.productType === this.productTypes.FRAME) {
        if (!this.currentProduct.width || !this.currentProduct.height) {
          return true;
        }
      } else if (
        !this.currentGlasOption &&
        this.currentProduct.productType === ProductTypes.GLAS_BOARD
      ) {
        return true;
      }

      const userObject = JSON.parse(
        localStorage.getItem("ibisOnlinebestellungUserObject")
      );

      if (
        userObject.customer_type !== "Fachhandel" ||
        !userObject.customer_type
      ) {
        return !this.currentProduct?.notes?.length > 0;
      }
      return false;
    },

    isOrderProcessing() {
      return this.$store.getters.isOrderProcessing;
    },

    userInfo() {
      const rawData = localStorage.getItem("ibisOnlinebestellungUserObject");
      if (!rawData) return null; // handle case where there's no userObject in localStorage

      // eslint-disable-next-line
      const { currentConfiguration, ...rest } = JSON.parse(rawData);
      return rest;
    },
  },

  data() {
    return {
      productTypes: ProductTypes,
      productOptionsToSort: ProductOptionsToSort,
      denominations: Denominations,
      familyTypes: FamilyTypes,
      toastTypes: ToastTypes,
      showToast: false,
    };
  },

  methods: {
    showToastMessage(toastMessage, type, inputId) {
      let payload = {
        showToast: true,
        toastType: type,
        message: toastMessage,
      };
      this.$store.commit("configureToast", payload);
      if (type === this.toastTypes.ERROR && inputId) {
        document.getElementById(inputId).classList.add("invalid");
        setTimeout(() => {
          document.getElementById(inputId).classList.remove("invalid");
        }, 3000);
      }
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 5000);
    },
    validateQuantityInput() {
      if (
        this.$store.getters.currentProduct.quantity < 1 &&
        this.$store.getters.currentProduct.quantity !== ""
      ) {
        this.$store.getters.currentProduct.quantity = 1;
      }
      if (this.currentProduct.only_full_units) {
        let quantity = this.currentProduct.quantity;
        let sellingUnit = this.currentProduct.selling_unit;

        let units = quantity / sellingUnit;
        this.currentProduct.quantity = Math.ceil(units) * sellingUnit;

        this.showToastMessage(
          `dieses Produkt kann nur in vollen VE bestellt werden.`,
          this.toastTypes.ERROR,
          "quantityInput"
        );
      }

      if (
        this.currentProduct.isRemainingStock === "True" &&
        this.currentProduct.remainingStockQuantity
      ) {
        if (
          this.$store.getters.currentProduct.quantity >
          this.currentProduct.remainingStockQuantity
        ) {
          this.$store.getters.currentProduct.quantity =
            this.currentProduct.remainingStockQuantity;

          this.showToastMessage(
            `Es sind nurnoch ${this.currentProduct.remainingStockQuantity} ${this.currentProduct.productType === this.productTypes.POLE
              ? "Meter"
              : this.currentProduct.productType ===
                this.productTypes.NON_CONFIGURABLE ||
                this.currentProduct.productType === this.productTypes.FRAME
                ? this.currentProduct.sub_category ===
                  this.productOptionsToSort.WOOD_FRAME ||
                  this.currentProduct.sub_category ===
                  this.productOptionsToSort.CORNER_FRAME
                  ? "Lfm."
                  : "Stk."
                : "Lfm."
            } verfügbar.`,
            this.toastTypes.ERROR,
            "quantityInput"
          );
        }
      }
      this.updatePrice();
    },
    validateAboveZeroAndFullNumbers() {
      if (
        this.$store.getters.currentProduct.quantity < 1 &&
        this.$store.getters.currentProduct.quantity !== ""
      ) {
        this.$store.getters.currentProduct.quantity = 1;
      }
      this.$store.getters.currentProduct.quantity = this.currentProduct.quantity
        ? this.currentProduct.quantity.toFixed(0)
        : 1;
      this.updatePrice();
    },
    heightValidation() {
      let value = this.allowOneDecimal(
        document.getElementById("heightInput").value.toString()
      );

      if (this.selectedConfigurations.includes(this.currentProduct) && !value) {
        this.$store.getters.currentProduct.setHeight(1, true);
      } else {
        this.$store.getters.currentProduct.setHeight(value, true);
        if (this.currentProduct.height !== parseFloat(value) && value) {
          this.showToastMessage(
            `Das technisch mögliche Mindestmaß ist ${this.currentProduct.min_width}x${this.currentProduct.min_height} cm - ${this.currentProduct.max_width}x${this.currentProduct.max_height} cm. Bitte passen Sie Ihr Maß dementsprechend an`,
            this.toastTypes.ERROR,
            "heightInput"
          );
        }
      }
      this.$forceUpdate();

      if (
        parseFloat(value) + this.currentProduct.width >
        this.currentProduct.max_height + this.currentProduct.max_width
      ) {
        this.showToastMessage(
          "Das Maximalmaß wurde überschritten.",
          this.toastTypes.ERROR,
          "heightInput"
        );
      }
      this.$store.getters.currentProduct.passepartout_use_custom_leg_width = false;
      if (
        this.currentProduct.width >= 80 && this.currentProduct.height >= 80
      ) {
        this.$store.getters.currentProduct.sf_with_cross = true;
        this.$store.getters.currentProduct.sf_with_bridge = false;
      }
      else if (
        this.currentProduct.width >= 80 ||
        this.currentProduct.height >= 80
      ) {
        this.$store.getters.currentProduct.sf_with_bridge = true;
        this.$store.getters.currentProduct.sf_with_cross = false;
      } else {
        this.$store.getters.currentProduct.sf_with_bridge = false;
        this.$store.getters.currentProduct.sf_with_cross = false;
      }

      this.updatePrice();
    },
    innerHeightValidation() {
      let value = this.allowOneDecimal(
        document.getElementById("innerHeightInput").value.toString()
      );

      if (this.selectedConfigurations.includes(this.currentProduct) && !value) {
        this.$store.getters.currentProduct.setPPInnerHeight(1);
      } else {
        this.$store.getters.currentProduct.setPPInnerHeight(value);
      }
      this.$forceUpdate();

      this.$store.getters.currentProduct.passepartout_use_custom_leg_width = false;
      this.syncConfiguration();
    },
    widthValidation() {
      let value = this.allowOneDecimal(
        document.getElementById("widthInput").value.toString()
      );
      if (this.selectedConfigurations.includes(this.currentProduct) && !value) {
        this.$store.getters.currentProduct.setWidth(1, true);
      } else {
        this.$store.getters.currentProduct.setWidth(value, true);
        if (this.currentProduct.width !== parseFloat(value) && value) {
          this.showToastMessage(
            `Das technisch mögliche Mindestmaß ist ${this.currentProduct.min_width}x${this.currentProduct.min_height} cm - ${this.currentProduct.max_width}x${this.currentProduct.max_height} cm. Bitte passen Sie Ihr Maß dementsprechend an`,
            this.toastTypes.ERROR,
            "widthInput"
          );
        }
      }
      this.$forceUpdate();
      if (
        this.currentProduct.height + parseFloat(value) >
        this.currentProduct.max_height + this.currentProduct.max_width
      ) {
        this.showToastMessage(
          "Das Maximalmaß wurde überschritten.",
          this.toastTypes.ERROR,
          "widthInput"
        );
      }
      this.$store.getters.currentProduct.passepartout_use_custom_leg_width = false;
      if (
        this.currentProduct.width >= 80 && this.currentProduct.height >= 80
      ) {
        this.$store.getters.currentProduct.sf_with_cross = true;
        this.$store.getters.currentProduct.sf_with_bridge = false;
      }
      else if (
        this.currentProduct.width >= 80 ||
        this.currentProduct.height >= 80
      ) {
        this.$store.getters.currentProduct.sf_with_bridge = true;
        this.$store.getters.currentProduct.sf_with_cross = false;

      } else {
        this.$store.getters.currentProduct.sf_with_bridge = false;
        this.$store.getters.currentProduct.sf_with_cross = false;
      }
      this.updatePrice();
    },
    innerWidthValidation() {
      let value = this.allowOneDecimal(
        document.getElementById("innerWidthInput").value.toString()
      );
      if (this.selectedConfigurations.includes(this.currentProduct) && !value) {
        this.$store.getters.currentProduct.setPPInnerWidth(1);
      } else {
        this.$store.getters.currentProduct.setPPInnerWidth(value);
      }
      this.$forceUpdate();

      this.$store.getters.currentProduct.passepartout_use_custom_leg_width = false;
      this.syncConfiguration();
    },
    allowOneDecimal(numStr) {
      const [wholeNum, decimalNum = ""] = numStr.split(".");

      if (decimalNum.length >= 1) {
        const roundedNum = Number.parseFloat(
          `${wholeNum}.${decimalNum}`
        ).toFixed(1);
        return roundedNum;
      } else {
        return parseFloat(numStr);
      }
    },

    onDenominationTypeChange(event) {
      this.$store.getters.currentProduct.quantity = 1;
      if (event?.target?.value === this.denominations.LEGS) {
        this.$store.getters.currentProduct.quantity = 1;

        this.$store.getters.currentProduct.height =
          this.$store.getters.currentProduct.defaultHeight;
      }
      this.updatePrice();
    },
    onNoCutOutInput() {
      this.$store.getters.currentProduct.passepartout_no_cut_out =
        !this.$store.getters.currentProduct.passepartout_no_cut_out;
      this.updatePrice();
    },
    onWithPolyInput() {
      this.$store.getters.currentProduct.with_poly =
        !this.$store.getters.currentProduct.with_poly;
      this.updatePrice();
    },
    onWithBridgeInput() {
      this.$store.getters.currentProduct.sf_with_bridge =
        !this.$store.getters.currentProduct.sf_with_bridge;

      if (
        (this.currentProduct.width <= 80 && this.currentProduct.height <= 80) ||
        this.$store.getters.currentProduct.sf_with_cross
      ) {
        this.$store.getters.currentProduct.sf_with_cross = false;
      }
      this.updatePrice();
    },
    onWithCrossInput() {
      this.$store.getters.currentProduct.sf_with_cross =
        !this.$store.getters.currentProduct.sf_with_cross;

      if (
        this.currentProduct.width <= 80 ||
        this.currentProduct.height <= 80 ||
        this.$store.getters.currentProduct.sf_with_bridge
      ) {
        this.$store.getters.currentProduct.sf_with_bridge = false;
      }
      this.updatePrice();
    },
    updatePrice() {
      this.syncConfiguration();
      this.$store.commit("updatePrice");
    },
    extractWidth(str) {
      const regex = /(\d+(?:[.,]\d+)?)\s*[xX]\s*(\d+(?:[.,]\d+)?)/;
      const match = regex.exec(str);
      if (match) {
        const width = parseFloat(match[1].replace(",", "."));
        return isNaN(width) ? null : width;
      } else {
        return null;
      }
    },

    extractHeight(str) {
      const regex = /(\d+(?:[.,]\d+)?)\s*[xX]\s*(\d+(?:[.,]\d+)?)/;
      const match = regex.exec(str);
      if (match) {
        const height = parseFloat(match[2].replace(",", "."));
        return isNaN(height) ? null : height;
      } else {
        return null;
      }
    },

    addProductToOrder() {
      this.$store.commit("addProductFamily");
      this.$store.commit("updateSelectedConfigurations");

      this.showToastMessage(
        "Produkt zur Bestellung hinzugefügt.",
        this.toastTypes.SUCCESS
      );
      this.syncConfiguration();
    },
    roundToTwo(num) {
      return +parseFloat(Math.round(num + "e+3") + "e-3");
    },
    selectAll(event) {
      event.target.select();
    },

    syncConfiguration() {
      DBSyncer.syncConfiguration();
    },
    doubleInputValidation() {
      this.validateAboveZeroAndFullNumbers();
      this.validateQuantityInput();
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../../assets/sass/style.scss";

.mainOptionsWrapper {
  display: flex;
  flex-flow: row;
  gap: 1vw;

  .optionsWrapper {
    width: 100%;

    .bottomRow {
      margin-top: 1.5em;

      .addToOrderButtonWrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 2rem;
      }
    }
  }

  .sizeUnitWrapper {
    display: flex;
    flex-direction: column;
    padding-top: 12.8rem;
    min-width: 1.5vw;

    span {
      height: fit-content;
    }
  }
}

input {
  padding: 12px 10px;
  box-shadow: none;
  border: 2px solid $ibis-black;
  border-radius: 50px;
  color: $ibis-black !important;
  font-size: $font-size-big;
  max-width: 4vw;
  text-align: center;

  @media (max-width: $mobile) {
    min-width: 3rem;
  }
}

.checkboxContainer {
  z-index: 1;
  margin-left: 0.5em;
  display: flex;
  position: relative;
  padding-left: 30px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 50px;
    background-color: $ibis-grey-middle;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  input:checked~.checkmark {
    background-color: $ibis-red;
    transition: 0.15s;

    &:after {
      display: block;
    }
  }

  .checkmark:after {
    left: 6.5px;
    top: 4px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.justify-end {
  justify-content: flex-end !important;
}

.invalid {
  border: 2px solid $ibis-red;
  color: $ibis-red;
}
</style>
