<template>
  <div class="productSelect">
    <select @change="emitChange()" v-model="selectedProduct">
      <option value="" v-if="type !== workshopProductSelectionTypes.CARRIER_MATERIAL &&
      type !== workshopProductSelectionTypes.STRETCHER_FRAME && !isFK1
      ">
        {{
      type === workshopProductSelectionTypes.BACK_WALL ? "Keine" : "Kein"
    }}
        {{ type }} / {{
      type === workshopProductSelectionTypes.BACK_WALL ? "vorhandene" :
        type === workshopProductSelectionTypes.STRETCHER_FRAME ? "vorhandener" :
          "vorhandenes"
    }} {{ type }}
      </option>
      <option value="" v-else disabled>
        {{
      currentSelectValue?.workshop_naming
        ? currentSelectValue?.workshop_naming
        : currentSelectValue?.description
    }}
      </option>
      <option v-for="(value, key) in products" :value="value" :key="key">
        {{ value.workshop_naming ? value.workshop_naming : value.description }}
      </option>
    </select>
  </div>
</template>
<script>
import WorkshopProductSelectionTypes from "@/models/Types/WorkshopProductSelectionTypes";

export default {
  props: ["products", "type", "isSecondGlas"],
  data() {
    return {
      selectedProduct: "",
      workshopProductSelectionTypes: WorkshopProductSelectionTypes,
    };
  },
  watch: {
    currentWorkshopWorkBackWall() {
      if (
        this.type === WorkshopProductSelectionTypes.BACK_WALL &&
        !this.currentWorkshopWorkBackWall
      ) {
        this.selectedProduct = "";
      }
    },
    currentWorkshopWorkGlas() {
      if (
        this.type === WorkshopProductSelectionTypes.GLAS &&
        !this.currentWorkshopWorkGlas && !this.isSecondGlas
      ) {
        this.selectedProduct = "";
      }
    },
    currentWorkshopWorkSecondGlas() {
      if (
        this.type === WorkshopProductSelectionTypes.GLAS &&
        !this.currentWorkshopWorkSecondGlas && this.isSecondGlas
      ) {
        this.selectedProduct = "";
      }
    },

    currentWorkshopWorkCarrierMaterial: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal.webshop_id && this.type === this.workshopProductSelectionTypes.BACK_WALL) {
          if (newVal !== oldVal) {
            if (newVal?.webshop_id === 10112626) {
              this.selectedProduct = this.products[0];
              this.emitChange();
            }
            else if (newVal.webshop_id === 10011541) {
              this.selectedProduct = undefined;
              this.emitChange();
            }
          }
        }
      },
      deep: true
    },
  },

  computed: {
    currentWorkshopWorkGlas() {
      return this.$store.getters.currentWorkshopWork.glas;
    },
    currentWorkshopWorkSecondGlas() {
      return this.$store.getters.currentWorkshopWork.second_glas;
    },
    currentWorkshopWorkBackWall() {
      return this.$store.getters.currentWorkshopWork.back_wall;
    },
    currentWorkshopWorkStretcherFrame() {
      return this.$store.getters.currentWorkshopWork.stretcher_frame;
    },
    currentWorkshopWorkCarrierMaterial() {
      return this.$store.getters.currentWorkshopWork.carrier_material;
    },
    currentSelectValue() {
      if (this.type === WorkshopProductSelectionTypes.GLAS) {
        return !this.isSecondGlas ? this.currentWorkshopWorkGlas : this.currentWorkshopWorkSecondGlas;
      } else if (this.type === WorkshopProductSelectionTypes.BACK_WALL) {
        return this.currentWorkshopWorkBackWall;
      } else if (this.type === WorkshopProductSelectionTypes.STRETCHER_FRAME) {
        return this.currentWorkshopWorkStretcherFrame;
      } else if (this.type === WorkshopProductSelectionTypes.CARRIER_MATERIAL) {
        return this.currentWorkshopWorkCarrierMaterial;
      }
      return undefined;
    },
    isFK1() {
      return this.$store.getters.currentWorkshopWork.carrier_material?.webshop_id === 10112626 && !this.$store.getters.currentWorkshopWork?.frame?.is_own_frame;
    }
  },
  methods: {
    emitChange() {
      this.$emit("changeProduct", this.selectedProduct);
    },
    presetBackWall(back_wall) {
      this.selectedProduct = back_wall;
    },
    presetGlas(glas) {
      this.selectedProduct = glas;
    },
    presetSecondGlas(second_glas) {
      this.selectedProduct = second_glas;
    },
    presetStretcher(stretcher_frame) {
      this.selectedProduct = stretcher_frame;
    },
  },

};
</script>
<style scoped lang="scss">
@import "../../../../assets/sass/style.scss";

select {
  padding: 12px 20px;
  box-shadow: none;
  border: 2px solid $ibis-black;
  border-radius: 50px;
  color: $ibis-black !important;
  font-size: $font-size-big;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url("../../../../assets/icons/arrow_down.svg");
  background-repeat: no-repeat;
  background-position: calc(97%) center;
  padding-right: 2em;
  min-width: 100%;
  max-width: 100%;

  @media (max-width: $mobile) {
    min-width: 100%;
  }
}

select::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

select:focus {
  outline: none;
  border-color: $ibis-black;
}
</style>
